import * as React from "react"
import { Link } from "gatsby"
import Menu from "./Menu"
import AnchorLink from 'react-anchor-link-smooth-scroll'

/* Image import */
import LogoPink from "../images/LogoPink.png"
import FooterLogo from "../images/FooterLogo.png"
import FollowMe from "../images/FollowMe.png"
import FooterGirl from "../images/footer-girl.png"
/* IconFont import */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header
  let footer

  header = (
    <div className="wrap g-nav-wrap">
          <Link to="/"><img src={ LogoPink } className="logo" alt="NuRseCall" /></Link>
          <Menu width={180}/>
          <ul className="g-nav-menu">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/app">アプリ</Link></li>
            <li><Link to="/info">お知らせ</Link></li>
            <li><Link to="/help-web">ヘルプ</Link></li>
          </ul>
    </div>
  )

  footer = (
    <div className="wrap footer-wrap">
      <div className="PageTop"><AnchorLink href="#pagetop" offset="50">
        <FontAwesomeIcon icon={faArrowAltCircleUp} /> PAGE TOP</AnchorLink>
      </div>
      <div className="footer-flexbox">
        <img src={ FooterLogo } className="footerlogo" alt="ナースのためのストレスマネジメントアプリーNuRseCall（ナースコール）" />
        <ul className="footer-nav-menu">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/app">アプリ</Link></li>
          <li><Link to="/info">お知らせ</Link></li>
          <li><Link to="/help-web">ヘルプ</Link></li>
          <li><Link to="/contact">お問い合わせ</Link></li>
          <li><a href="https://www.startup-plus.com" target="_blank">運営会社</a></li>
        </ul>
      </div>
      <div className="footer-flexbox2">
        <div className="sns-follow">
          <div className="TaCenter">
            <img src={ FollowMe } alt="Follow Me" className="FollowMe"></img>
            <ul class="sns-list">
            <li>
              <a href="https://twitter.com/NursecallApp" target="_blank">
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/NurseCallApp" target="_blank">
                <FontAwesomeIcon icon={faTwitterSquare} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/NurseCallApp" target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
          </ul>
          </div>
          <img src={ FooterGirl } alt="GirlType" className="GirlType"></img>
        </div>
        <div className="copyright">
        Copyright {new Date().getFullYear()}, 
        {` `}
        NuRseCall
        {` `}
        All Rights Reserved.
        </div>
      </div>
    </div>

  )

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer className="global-footer">{footer}</footer>
    </div>
  )
}

export default Layout
